<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row>
      <v-col md="6" cols="12">
        <h3 class="title mt-5 text-right">
          KERRY :: COD DATE Remittance
        </h3>
      </v-col>
      <v-col md="6" cols="12">
        <v-file-input
          label="File input นามสกุล xlsx เท่านั้น"
          accept=".xlsx"
          prepend-icon="mdi-file-excel"
          type="file"
          @change="handleChange"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-card>
        <v-card-title>
            <h3 class="subtitle-1 mb-2">{{ codInformation }}</h3>
        </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataTrack"
        item-key="track"
        :loading="dataTableLoading"
        hide-default-footer
        disable-pagination
        show-select
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="grey lighten-4" light height="55">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-7" inset vertical=""></v-divider>
            {{ totalTracking }} {{ responseUpdate }}
            <v-spacer></v-spacer>
            <v-btn
              color="yellow darken-1"
              @click="updateCODRemittance"
              light
              class="mb-1 mr-1"
              small
            >
              <v-icon class="mr-1">mdi-database</v-icon>
              Update COD Remittance
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected, index }">
          <tr :key="index">
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td class="lime lighten-3">
              {{ item.track }}
            </td>
            <td class="lime lighten-2">
              <span class="caption">{{ item.remittance }}</span>
            </td>
            <td class="yellow lighten-4">
              {{ item.cash | currency("฿", 2) }}
            </td>

            <td class="blue lighten-5 caption">
              <span v-if="item.updated" class="green--text"
                ><v-icon small color="green"
                  >mdi-checkbox-marked-circle</v-icon
                ></span
              >
              <span class="red--text" v-else
                ><v-icon small color="red">mdi-close-circle</v-icon></span
              >
              {{ item.message }} : <span v-if="item.data">{{ item.data.cod_status == codstatus.PAID ? `โอนแล้ว ${item.data.cod_refpaid}`:'รอโอน' }}</span>
            </td>
            <td class="blue lighten-5">
              {{ item.data.idorderformat }} | {{ item.data.name }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api, { CODSTATUS } from "@/services/api";
import Exceljs from "exceljs";
import _ from "lodash";

export default {
  name: "UploadTrackingKerryAcc",
  data() {
    return {
      codstatus: CODSTATUS,
      selected: [],
      dataTableLoading: false,
      file: "",
      data: [],
      codInfo: {
        dateRef: "",
        paidRef: ""
      },
      dataTrack: [],
      dataUpadted: [],
      headers: [
        { text: "Tracking No", value: "track", class: "lime lighten-1" },
        { text: "วันที่โอน", value: "remittance", class: "lime lighten-1" },
        {
          text: "Cash",
          value: "cash",
          class: "yellow lighten-1",
        },
        {
          text: "ข้อมูลระบบ",
          value: "updated",
          class: "blue lighten-4",
        },
        { text: "Name in DB", value: "name2", class: "blue lighten-4" }
      ],
    };
  },
  computed: {
    totalTracking() {
      let total = `Record(s): ${this.dataTrack.length}`;
      let orderfounds = _.filter(this.dataTrack, { inOrder: true });
      let totalfound = ` found(s): ${orderfounds.length}`;
      return `${total} ${totalfound}`;
    },
    responseUpdate() {
      let rSucceed = _.filter(this.dataTrack, { resultupdated: true });
      let rFailed = _.filter(this.dataTrack, { resultupdated: false });
      return ` succeed: ${rSucceed.length} failed: ${rFailed.length}`;
    },
    codInformation() {
      return (
        "Recipient ID: " +
        this.codInfo.paidRef +
        " Date: " +
        this.codInfo.dateRef
      );
    },
  },
  methods: {
    handleChange(e) {
      // console.log(e);
      // this.file = e.target.files[0];
      this.selected = [];
      if (e) {
        this.file = e;
        this.handleImport();
      } else {
        this.dataTrack.splice(0, this.dataTrack.length);
        this.data.splice(0, this.data.length);
      }
    },
    handleImport() {
      this.dataTableLoading = true;
      const wb = new Exceljs.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(this.file);
      let sourcefile = this.file.name.split('_');
      this.codInfo.paidRef = this.file.name.replaceAll('_','');
      this.codInfo.dateRef = this.$moment(sourcefile[1]).format("YYYY-MM-DD");
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          //console.log(workbook, 'workbook instance')
          workbook.eachSheet((sheet, id) => {
            //   console.log(sheet.rowCount)
            let totalRow = sheet.rowCount;
            console.log(totalRow)
            sheet.eachRow((row, rowIndex) => {
                // start แถวที่ 5 และ ไม่รวมแถมสุดท้าย 
              if (rowIndex > 5 && rowIndex < totalRow ) {
                if (_.trim(row.values[2])!='') {
                  let remittance = row.values[13];
                  let dataRow = {
                    id: rowIndex,
                    remittance: this.$moment(remittance).format(
                      "YYYY-MM-DD"
                    ),
                    track: _.trim(row.values[2]),
                    cash: _.trim(row.values[8]),
                    inOrder: false, // ไว้เช็คค่าในออเดอร์,
                    updated: false,
                    resultupdated: null,
                    message: "ไม่มีออเดอร์นี้",
                  };
                  this.data.push({ ...dataRow });
                }
              } 
            });
          });
          // console.log(this.data)
          this.dataTableLoading = false;
          this.checkTrackToOrder();
        });
      };
    },
    
    async checkTrackToOrder() {
      // this.data.pop(); // ตัดแถวสุดท้ายออก เพราะเป็นข้อมูล ยอดรวม ใน excel
      if (this.data.length > 0) {
        this.dataTableLoading = true;
        let formData = new FormData();
        formData.append("items_selected", JSON.stringify(this.data));

        let result = await api.getOrderByTracks(formData);
        if (result.data.result == "ok") {
          _.delay(() => {
            this.dataTrack = JSON.parse(result.data.message);
            this.dataTableLoading = false;
          }, 1000);
        }
      }
    },
    async updateCODRemittance() {
      if (confirm("ต้องการอัพเดต KERRY COD Remittance ใช่หรือไม่?")) {
        this.dataTableLoading = true;
        if (this.selected.length) {
            let formData = new FormData();
            formData.append("items_selected", JSON.stringify(this.selected));
            formData.append("codref", _.trim(this.codInfo.paidRef));
            formData.append("dateref", _.trim(this.codInfo.dateRef));

            let result = await api.updateCodRemittanceKerry(formData);
            if (result.data.result == "ok") {
              let data = JSON.parse(result.data.message);
              let initdata = this.dataTrack;

              _.forEach(data, (r, i) => {
                let trackindex = _.findIndex(initdata, { track: r.track });
                if (trackindex > -1) {
                  initdata[trackindex].data.cod_status = r.data.cod_status;
                  initdata[trackindex].message = r.message;
                  initdata[trackindex].updated = r.updated;
                  initdata[trackindex].resultupdated = r.resultupdated;

                }
              });

              this.dataTrack = [];
              _.delay(() => {
                this.dataTrack = initdata;
                this.dataTableLoading = false;
              }, 2000);
            }

        }
      }
    },
  },
  beforeDestroy: function() {
    delete this.selected;
    delete this.data;
    delete this.dataTrack;
    delete this.dataUpadted;
  },
};
</script>

<style></style>
